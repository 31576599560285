import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Section } from "../../layout";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Does sharing information about great trails and beautiful places have a negative effect on mountain biking?`}</h4>
    <p>{`I see more and more discussions about this. Here is why I think there are many
positive effects!`}</p>
    <Image src="/news/trailguidephone1.jpg" className="w-3/4 mx-auto my-12" mdxType="Image" />
    <p>{`The problem isn‘t the sharing itself and the public access to the information.
The problems are the missing rules and structures for mountain biking in our
global and highly populated world.`}</p>
    <br />
    <p>{`Publishing pictures and videos and at the same time intending not to attract
others, is contradictory and boastful behaviour. Bragging with your secret
knowledge of beautiful places, getting admiration, likes, clicks, and sales.
But don‘t you dare thinking of visiting this amazing place yourself!`}</p>
    <br />
    <h4>{`Simply don‘t publish anything then!?`}</h4>
    <p>{`The need to tell others about your own experiences feels quite natural. Share
them with your partner, friends, followers, readers, users, everyone who make
up our social environment in 2020. “Only I am allowed to ride this trail,
no one else“. Sounds a bit selfish, doesn‘t it?`}</p>
    <br />
    <h4>{`Information spreads like a virus.`}</h4>
    <p>{`With the current pandemic everyone should know by now the number of people you
can share information with before it spreads exponentially. That it is
impossible to contain to a few.`}</p>
    <br />
    <p>{`Every guided tour would become a “super spreader“ event. Also social media is
only one type of medium. Should all this be forbidden as well: cartographical material,
guided tours, magazines, books, presentations, meetings with friends to talk
about the latest tours and discoveries.`}</p>
    <br />
    <h4>{`With more information, more people know about the great possibilities of the sport, and therefore more people will do the sport. But that is good!`}</h4>
    <ul>
      <li parentName="ul">
        <i>
  Being many mountain bikers
        </i> results in a lobby, clubs, tourism associations
which establish legal and open trails where we can ride our bikes on.
        <br />
        <br />
      </li>
      <li parentName="ul">
        <i>Being many mountain bikers</i> creates a market that pushes the product
development so we can have great equipment and bikes that make biking
so much more fun.
        <br />
        <br />
      </li>
      <li parentName="ul">
        <i>Being many mountain bikers</i> creates an economy which is the means of
existence for many people.
        <br />
        <br />
      </li>
      <li parentName="ul">
        <i>Being many mountain bikers</i> means that many people are out in the nature
and learn to appreciate it, protect it to preserve it, and become healthier
through the sport.
        <br />
        <br />
      </li>
      <li parentName="ul">
        <i>
  Being many mountain bikers
        </i> builds up social structures to enthuse others
for the sport, and to give their values to members, beginners and children.
        <Image src="/news/bestof2017/bestof_27.jpg" className="w-3/4 mx-auto my-12" mdxType="Image" />
      </li>
    </ul>
    <h4>{`Does sharing information also have other, less obvious effects?`}</h4>
    <p>{`Won‘t the traffic rather be distributed to many different places instead
of everyone concentrating on a few well known places? Can this even reduce the
load on the individual trail to a sustainable level?`}</p>
    <br />
    <p>{`Will easy access to information make access to our sport easier and will more
people find access to nature, health and fun? Isn't that a picture of a better
society?`}</p>
    <br />
    <h4>{`But back to the statement of the beginning: The problem is missing rules and structures.`}</h4>
    <p>{`There are some known conflicts in MTB:`}</p>
    <ul>
  1. <b>Errosion</b>. Suggested solution: It has to be self evident that it
  takes quite an effort to build and maintain trails. This work has either to be
  financed by the bikers, through tolls or indirectly through tourism or taxes,
  or they have to work on the trails themselves.
  <br />
  <br />
  2. <b>Equality and security of all user groups</b>. Suggested solution: Respectful
  behaviour, anticipatory riding. If many mountain bikers are organized in clubs
  and associations it is easy to give these values to members, beginners and children.
  <br />
  <br />
  3. <b>Fauna and flora. Suggested solution</b>: Attractive and legal
  possibilities for biking prevents wild and illegally built trails. Mountain
  biking happens on created trails. These legal possibilities can be created, or
  existing ones can be optimized in cooperation with experts, for animals and
  plants to have the space they need. Organizational solutions can manage and
  shift the traffic in sensitive times of the year.
    </ul>
    <h4>{`Then everyone can go biking and share adventures carefree!`}</h4>
    <p>{`*`}{`Warning: I am not an expert and these are only my personal ideas for some
solutions. The core of the statement is that we should stop beeing selfish
and protective, behaviour which helps no one, but rather creates more
conflicts and prevent the mountain bike community from evolving. `}<b>{`We
should start to think and act more solution oriented!`}</b></p>
    <br />
    <br />
    <br />
    <h4>{`What do you think about sharing? We are interested in your opinion!`}</h4>
    <p>{`Send us your feedback or discuss it with us in our
`}<a parentName="p" {...{
        "href": "https://www.facebook.com/groups/184610292286074/"
      }}>{`Facebook Group`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      